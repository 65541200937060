<script setup lang="ts">
import IconAva from '@/shared/assets/icons/IconAva.svg'

const props = defineProps({
  src: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: '',
  },
  size: {
    type: String as () => 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl',
    default: 'xs',
  },
  type: {
    type: String as () => 'circle' | 'rectangle',
    default: 'circle',
  },
  online: {
    type: Boolean,
    default: false,
  },
  hover: {
    type: Boolean,
    default: false,
  },
  active: {
    type: Boolean,
    default: false,
  },
})

const loaded = ref(false)
const shortName = computed(() => {
  if (!props.name) return ''
  const names = props.name.split(' ')
  if (names.length === 1) return names[0].charAt(0) + names[0].charAt(1)
  return names[0].charAt(0) + names[names.length - 1].charAt(0)
})
</script>

<template>
  <div
    class="group/avatar relative flex shrink-0 grow-0 select-none items-center justify-center"
    :class="{
      'h-24 w-24': size === 'xs',
      'h-32 w-32': size === 's',
      'h-40 w-40 transition-all': size === 'm',
      'h-48 w-48 transition-all': size === 'l',
      'h-56 w-56 transition-all': size === 'xl',
      'h-80 w-80 transition-all': size === 'xxl',
      skeleton: src,
      'rounded-full': type === 'circle',
      'rounded-18': type === 'rectangle' && size === 'xxl',
      'rounded-16': type === 'rectangle' && size === 'xl',
      'rounded-12': type === 'rectangle' && (size === 'l' || size === 'm'),
      'rounded-8': type === 'rectangle' && size === 's',
      'rounded-6': type === 'rectangle' && size === 'xs',
      'active:bg-primary cursor-pointer hover:bg-gray-400 active:text-white': hover && !active,
      'hover:opacity-75 active:opacity-100': hover && src && !active,
      'text-gray-850 bg-gray-100': !active,
      'bg-primary text-white': active,
    }"
  >
    <img
      v-if="src"
      class="h-inherit w-inherit rounded-inherit object-cover transition-opacity duration-300"
      :class="{
        'opacity-0': !loaded,
        'opacity-100': loaded,
      }"
      :src="src"
      alt=""
      @load="loaded = true"
    />
    <div
      v-else-if="shortName && ['xxl', 'xl', 'l', 'm'].includes(size)"
      class="text-h6-semibold uppercase"
    >
      {{ shortName }}
    </div>
    <div v-else-if="['s', 'xs'].includes(size)">
      <IconAva
        :width="size === 's' ? 20 : 16"
        :height="size === 's' ? 20 : 16"
        :class="{
          'group-active/avatar:bg-primary group-hover/avatar:bg-gray-400 group-hover/avatar:fill-black group-active/avatar:fill-white':
            hover && !active,
          'fill-gray-600': !active,
          'bg-primary fill-white': active,
        }"
      />
    </div>
    <div
      v-if="online"
      class="bg-leaf absolute rounded-full border-2 border-solid border-white"
      :class="{
        'bottom-2 right-2': ['xxl', 'xl'].includes(size),
        'bottom-[1px] right-[1px]': size === 'l',
        'bottom-0 right-0': ['m', 's', 'xs'].includes(size),
        'h-16 w-16': ['xxl'].includes(size),
        'h-12 w-12': ['xl', 'l', 'm'].includes(size),
        'h-8 w-8': ['s', 'xs'].includes(size),
      }"
    />
  </div>
</template>
